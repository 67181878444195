import { useState, useEffect } from 'react';
import { render } from 'react-dom';
const $ = jQuery;

import './style.scss';
import GalleryHeader from './GalleryHeader';
import Gallery from './Gallery';
import { fetchUserWithData, setLocalWithExpiry, getLocalWithExpiry } from './services';
import Style from './Style';
import { hasObjProperty } from './utils/function';

const timeToMs = (time, period) => {
	const minuteMS = 60000;

	switch (period) {
		case 'minutes':
			return minuteMS * time;
		case 'hours':
			return 60 * minuteMS * time;
		case 'days':
			return 1440 * minuteMS * time;
		case 'months':
			return 43200 * minuteMS * time;
		case 'years':
			return 525600 * minuteMS * time;
	}
}

// All Instagram Feed
document.addEventListener('DOMContentLoaded', () => {
	const allInstagramFeed = document.querySelectorAll('.wp-block-ifb-instagram');
	allInstagramFeed.forEach(instagramFeed => {
		const attributes = JSON.parse(instagramFeed.dataset.attributes);
		const { cId, accessToken = [] } = attributes;

		render(accessToken[0] ? <div className='ifbInstagramFeed'>
			<Style attributes={attributes} clientId={cId} />

			<GalleryRender attributes={attributes} />
		</div> : <></>, instagramFeed);

		instagramFeed?.removeAttribute('data-attributes');
	});
});

// GalleryRender
const GalleryRender = ({ attributes }) => {
	const { cId, accessToken, cacheTime, cacheTimePeriod } = attributes;

	const [users, setUsers] = useState(getLocalWithExpiry(`ifbData-${cId}`)?.users || []);
	const [usersData, setUsersData] = useState(getLocalWithExpiry(`ifbData-${cId}`)?.usersData || {});
	const [pageNumber, setPageNumber] = useState(1);

	const userName = users?.length ? users[0]?.username : '';
	const userData = userName && usersData && hasObjProperty(usersData, userName) ? usersData[userName] : {};

	// useEffect(() => {
	// 	token && fetchUser(token).then(data => {
	// 		!users?.length && setUsers([data]);
	// 	});
	// }, [token]);

	// useEffect(() => {
	// 	token && userName && fetchUserData(token, userName).then(data => {
	// 		$.isEmptyObject(userData) && setUsersData({ [userName]: data });
	// 	});
	// }, [token, userName]);

	useEffect(() => {
		let usersHolder = [];
		let usersDataHolder = {};

		accessToken?.length && accessToken.map(token => {
			// accessToken?.length !== users?.length && fetchUser(token).then(data => {
			// 	usersHolder.push(data);
			// 	setUsers(usersHolder);
			// });

			accessToken?.length !== Object.keys(usersData)?.length && fetchUserWithData(token).then(data => {
				usersHolder.push(data.user);
				usersDataHolder = { ...usersDataHolder, ...data.userData }

				setUsers(usersHolder);
				setUsersData(usersDataHolder);
			});
		});
	}, [accessToken]);

	useEffect(() => {
		users?.length && !$.isEmptyObject(usersData) && setLocalWithExpiry(`ifbData-${cId}`, {
			users,
			usersData
		}, timeToMs(cacheTime, cacheTimePeriod));
	}, [users, usersData]);

	return !$.isEmptyObject(usersData) ? <>
		<GalleryHeader userName={userName} attributes={attributes} />

		<Gallery media={userData.media} attributes={attributes} pageNumber={pageNumber} setPageNumber={setPageNumber} />
	</> : '';
}