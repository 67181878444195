import { getBackgroundCSS, getBorderCSS, getSpaceCSS, getTypoCSS } from '../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { columnGap, rowGap, background, padding, border, captionBG, captionTypo, captionColor } = attributes;

	const feedSl = `#ifbInstagramFeed-${clientId} .ifbInstagramFeed`;
	const galleryItemSl = `${feedSl} .galleryItem`;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS('', captionTypo)?.googleFontLink}
		${getTypoCSS(`${galleryItemSl} figcaption p`, captionTypo)?.styles}

		${feedSl}{
			${getBackgroundCSS(background)}
			padding: ${getSpaceCSS(padding)};
			${getBorderCSS(border)}
		}
		${feedSl} .galleryHeader{
			margin-bottom: ${rowGap};
		}
		${feedSl} .ifbGallery{
			grid-gap: ${rowGap} ${columnGap};
		}
		${galleryItemSl} figcaption{
			${getBackgroundCSS(captionBG)}
		}
		${galleryItemSl} figcaption p{
			color: ${captionColor};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;