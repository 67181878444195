import { truncate } from './utils/function';

const Gallery = ({ media, attributes, pageNumber, setPageNumber }) => {
	const { itemVisible, isLink, isLinkNewTab, isCaption, captionLength, isLoadMore, columns, imgHoverEffect, captionStyle } = attributes;

	return <>
		<div className={`ifbGallery columns-${columns.desktop} columns-tablet-${columns.tablet} columns-mobile-${columns.mobile}`}>
			{media?.length && media.slice(0, itemVisible * pageNumber).map((data, index) => {
				const { id, media_type, media_url, caption, permalink } = data; // timestamp present in data

				// const galleryCarousel = useRef(null);
				// const slidePrev = useRef(null);
				// const slideNext = useRef(null);

				// useEffect(() => {
				// 	if (galleryCarousel.current && slidePrev.current && slideNext.current) {
				// 		new Swiper(galleryCarousel.current, {
				// 			speed: 400,
				// 			slidesPerView: 1,
				// 			spaceBetween: 0,
				// 			navigation: {
				// 				prevEl: slidePrev.current,
				// 				nextEl: slideNext.current,
				// 			}
				// 		});
				// 	}
				// }, [galleryCarousel.current, slidePrev.current, slideNext.current]);

				const imageEl = <figure className={`galleryFigure ${imgHoverEffect}`}>
					<img src={'VIDEO' === media_type ? data.thumbnail_url : media_url} alt={caption} />

					{isCaption ? <figcaption className={captionStyle}><p>{truncate(caption, captionLength)}</p></figcaption> : ''}
				</figure>

				return <div key={id} className='galleryItem' id={`galleryItem-${index}`}>
					{isLink ? <a href={permalink} target={isLinkNewTab ? '_blank' : '_self'} rel='noreferrer'>{imageEl}</a> : imageEl}
					{/* {isCaption ? <div className={`caption ${captionStyle}`}><p>{truncate(caption, captionLength)}</p></div> : null} */}

					{/* {'IMAGE' === media_type && <img src={media_url} alt={caption} />}

					{'VIDEO' === media_type && <video controls poster={data.thumbnail_url}>
						<source src={media_url} />
					</video>}

					{'CAROUSEL_ALBUM' === media_type && <div className='galleryCarousel' ref={galleryCarousel}>
						<div className='swiper-wrapper'>
							{data.children.data.map(child => <div className='swiper-slide' key={child.id}>
								{child?.thumbnail_url ?
									<video controls poster={child.thumbnail_url}>
										<source src={child.media_url} />
									</video> :
									<img src={child.media_url} alt={caption.split(' ').slice(0, 12).join(' ')} />}
							</div>)}
						</div>
						<div className='swiper-button-prev' ref={slidePrev}>
							<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path d="M14.71 15.88L10.83 12l3.88-3.88c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z" /></svg>
						</div>
						<div className='swiper-button-next' ref={slideNext}>
							<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path d="M9.29 15.88L13.17 12 9.29 8.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z" /></svg>
						</div>
					</div>} */}
				</div>;
			})}
		</div>

		{isLoadMore && <div className={`loadMore ${media?.length > itemVisible * pageNumber ? 'visible' : 'hidden'}`}>
			<button onClick={() => setPageNumber(pageNumber + 1)}>Load More</button>
		</div>}
	</>;
}
export default Gallery;