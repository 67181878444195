const GalleryHeader = ({ userName, attributes }) => {
	const { isProfile, profileImg, isFollowBtn } = attributes;

	const link = `https://www.instagram.com/${userName}/`;

	return isProfile || isFollowBtn ? <div className='galleryHeader'>
		{isProfile && <div className='headerLeft'>
			<figure className='profileImg'>
				<a href={link} target='_blank' rel='noreferrer'><img src={profileImg} alt={userName} /></a>
			</figure>

			<h3 className='userName'><a href={link} target='_blank' rel='noreferrer'>@{userName}</a></h3>
		</div>}

		{isFollowBtn && <div className='headerRight'>
			<a className='followBtn' href={link} target='_blank' rel='noreferrer'>Follow On Instagram</a>
		</div>}
	</div> : null;
}
export default GalleryHeader;